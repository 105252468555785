import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const currentUser = this.authService.currentUserValue;

    const currentUser = localStorage.getItem('userId');
    // const currentUserType = localStorage.getItem('role');
    if (currentUser) {
      return true;
      //   if (currentUserType === 'ADMIN' || currentUserType === 'INTERNAL') {
      //     return true;
      //   } else {
      //     this.router.navigate(['/s']);
      //     // logged in so return true
      //     return false;
      //   }
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    // localStorage.clear();
    // this.router.navigate(['/auth/login'], {
    //   queryParams: { returnUrl: state.url },
    // });
    return false;
  }
}
